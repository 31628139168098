import React, { useState, useEffect } from "react"
import _ from "lodash"

import { FaMap } from "react-icons/fa"
import { Text, Select, Flex, Image, Box } from "@chakra-ui/core"
import startImg from "../../images/37255.jpg"
import nextImg from "../../images/office.svg"
import territoires from "./dataset/territoires.json"

function NextSelect({ codzone, setCodzone }) {
  return (
    <Box w="100%">
      <Text fontWeight="800">
        <Box as={FaMap} display="inline" m={2} />
        Vous consultez les données sur
      </Text>
      <Select
        placeholder="Sélectionnez un département"
        onChange={item => setCodzone(item.target.value)}
        value={codzone}
      >
        {_.map(territoires, dep => (
          <option value={dep.codzone} key={dep.codzone}>
            {dep.nom}
          </option>
        ))}
      </Select>
    </Box>
  )
}

function StartSelect({
  codzone,
  setCodzone,
  setFirstDisplay,
  isFirstDisplayed,
  indicateur,
  setIndicateur,
}) {
  return (
    <Flex flexDirection="row" alignItems="center" flexGrow="1">
      {!isFirstDisplayed ? (
        <Image src={startImg} htmlWidth="10%" />
      ) : (
        <Image src={nextImg} htmlWidth="5%" />
      )}

      <Flex flexDirection="column" flexGrow="1" mr={4}>
        {!isFirstDisplayed ? (
          <Text fontWeight="800">
            Découvrez le baromètre Compas pour votre département
          </Text>
        ) : (
          <Text fontWeight="600" fontSize="sm">
            Vous consultez les données sur
          </Text>
        )}

        <Select
          placeholder="Sélectionnez un département"
          onChange={item => {
            if (!isFirstDisplayed) {
              setFirstDisplay(true)
            }
            setCodzone(item.target.value)
          }}
          value={codzone}
        >
          {_.map(territoires, dep => (
            <option value={dep.codzone} key={dep.codzone}>
              {dep.nom}
            </option>
          ))}
        </Select>
      </Flex>
      {codzone && (
        <Flex flexDirection="column" flexGrow="1" mr={4}>
          
            <Text fontWeight="600" fontSize="sm">
             pour la situation portant sur
            </Text>
         

          <Select
           
            onChange={item => {
              setIndicateur(item.target.value)
            }}
            value={indicateur || 0}
          >
            <option value={0}>Les difficultés dans l’usage du numérique</option>
            <option value={1}>Les besoins pour l’accès aux droits</option>
          </Select>
        </Flex>
      )}
    </Flex>
  )
}

export default function SelectTerr({
  codzone,
  setCodzone,
  indicateur,
  setIndicateur,
  selectContainer,
  headerContainer,
}) {
  const [isFirstDisplayed, setFirstDisplay] = useState(false)
  useEffect(() => {
    if (isFirstDisplayed) {
      window.scrollTo({
        top:
          selectContainer.current.offsetTop -
          headerContainer.current.offsetHeight,
        behavior: "smooth",
      })
    }
  }, [isFirstDisplayed, codzone, headerContainer, selectContainer])

  return (
    <Flex mr={10} ml={10} ref={selectContainer} w="100%">
      <StartSelect
        setCodzone={setCodzone}
        codzone={codzone}
        indicateur={indicateur}
        setIndicateur={setIndicateur}
        setFirstDisplay={setFirstDisplay}
        isFirstDisplayed={isFirstDisplayed}
      />
    </Flex>
  )
}
