import React from "react"
import {
  Flex,
  Text,
  Box,
  Stack,
  Icon,
  Link as ChakraLink,
  Image,
} from "@chakra-ui/core"

import image from "../../images/43122.jpg"
import startImg from "../../images/Sonar_logo_V4-250x131.png"
import imgLogo from "../../images/logo.png"
export default function Home() {
  return (
    <>
      <Flex alignItems="center" mr={10} ml={10}>
        <Flex flex={10} direction="column" flexBasis="70%" pr={10}>
          <Stack spacing={5} mb={10}>
            <Text fontSize="lg" fontWeight="600">
              Fragilité numérique : l’accès et le besoin
            </Text>
            <Text>
              La crise sanitaire a mis en évidence les différences, d’accès,
              d’usage et de maîtrise des nouvelles formes de communication, et
              ces difficultés se sont amplifiées lors de l’usage des plateformes
              numériques.
            </Text>

            <Text>
              Aux difficultés de compréhension de textes s’ajoutent les
              difficultés face à l’usage des outils. Le parti-pris est celui
              d’un numérique qui rend plus facile l’accès à l’information. Si
              cela est le cas pour une très grande majorité des français, à
              l’inverse il complexifie cet accès pour les autres.
            </Text>

            <Text>
              Le{" "}
              <ChakraLink href="http://www.lecompas.fr/" isExternal>
                Compas
                <Icon name="external-link" mx="2px" />
              </ChakraLink>{" "}
              présente un{" "}
              <Text as="span" style={{ fontWeight: "600" }}>
                baromètre©Compas{" "}
              </Text>
              du numérique qui a pour objectif d’aider les collectivités locales
              à mieux appréhender les lieux où il y a soit le plus besoin du
              numérique pour l’accès aux droits soit où se situent les personnes
              qui ont le moins de ressources pour accéder de manière autonome à
              l’e-administration.
            </Text>
          </Stack>
        </Flex>
        <Flex
          flexBasis="20%"
          flexDirection="column"
          fontSize="xs"
          alignItems="center"
          textAlign="center"
        >
          <Text as="strong">Mieux nous connaître</Text>
          <Image src={imgLogo} my={1} />
          <ChakraLink
            href="http://www.lecompas.fr"
            isExternal
            backgroundColor="rgb(22,171,228)"
            color="white"
            p="1"
            my={1}
          >
            lecompas.fr
            <Icon name="external-link" mx="2px" />
          </ChakraLink>
        </Flex>
      </Flex>
    </>
  )
}
